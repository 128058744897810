import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import { InfoWindow } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "80vh",
};

const center = {
  lat: 51.3887853,
  lng: 0.5182592,
};

function MyComponent() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyA9Fo_Ybvpk6kCVkNEsxuzQcq0Ojg9CdcY">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        <>
          <Marker position={center} />
        </>
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(MyComponent);
