import {
  Box,
  Heading,
  Image,
  Paragraph,
  TextArea,
  TextInput,
  Grid,
  Form,
  FormField,
} from "grommet";
import { gsap } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import commercial from "../assets/images/about/commercial.jpg";
import education from "../assets/images/about/education.jpg";
import health from "../assets/images/about/health.jpg";
import CategoryCommercial from "../assets/svgs/commercial.svg";
import CategoryEducation from "../assets/svgs/education.svg";
import CategoryHealth from "../assets/svgs/health.svg";
import LinkArrow from "../assets/svgs/link-arrow.svg";
import Layout from "../components/layout";
import MapContainer from "../components/Maps";
import Seo from "../components/seo";
import { useResponsive } from "../hooks";
import { navigate } from "gatsby-link";
import Button from "../components/Button";
import DivisionShowcase from "../components/DivisionShowcase";
import {StaticImage} from "gatsby-plugin-image";

const CategoryImages = {
  Commercial: {
    logo: (
      <CategoryCommercial
        width="75%"
        height="100%"
        style={{
          maxWidth: "300px",
        }}
      />
    ),
    image: commercial,
  },
  Health: {
    logo: (
      <CategoryHealth
        width="75%"
        height="100%"
        style={{
          maxWidth: "300px",
        }}
      />
    ),
    image: health,
  },
  Education: {
    logo: (
      <CategoryEducation
        width="75%"
        height="100%"
        style={{
          maxWidth: "300px",
        }}
      />
    ),
    image: education,
  },
};

const Category = ({
  title = "",
  position = 50,
  maxWidth = 400,
  onClick = null,
}) => {
  const arrowRef = useRef(null);
  const [hover, setHover] = useState(false);
  const { isMobile, isDesktop, size } = useResponsive();

  useEffect(() => {
    hover &&
      gsap.to(arrowRef.current, {
        right: 0,
        duration: 0.25,
      });

    !hover &&
      gsap.to(arrowRef.current, {
        right: position,
        duration: 0.25,
      });
  }, [hover]);

  return (
    <Box
      onClick={() => (onClick ? onClick() : window.open(`mailto:${title}`))}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      flex="shrink"
      justify="center"
      fill="horizontal"
      style={{ overflow: "hidden" }}
      direction="column">
      <Box
        flex={false}
        fill="horizontal"
        width={{ max: `${maxWidth}px` }}
        style={{
          overflow: "hidden",
          position: "relative",
          height: 100,
        }}>
        <Box style={{ position: "relative" }} fill="horizontal">
          <Paragraph
            style={{ position: "absolute", top: isMobile ? 10 : 0 }}
            color="white"
            size={isMobile ? "medium" : "large"}>
            {title}
          </Paragraph>
          <Box
            ref={arrowRef}
            height="40px"
            width="150%"
            flex={false}
            align="end"
            style={{ position: "relative", top: 45, right: position }}>
            <LinkArrow stroke="white" color={"white"} style={{ width: "200%" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const IntroSection = () => {
  const { isMobile, isDesktop, size, isSmallMobile } = useResponsive();

  return (
    <Box
      fill="horizontal"
      width={{ max: "maxWidth" }}
      margin={{ bottom: "xlarge" }}>
      {isSmallMobile && (
        <Box
          pad={{ top: "large", bottom: "medium", horizontal: "large" }}
          flex="grow">
          <Heading level={1} color="red" size="medium" margin="none">
            We Build Ideas
          </Heading>
        </Box>
      )}
      <Grid
        gap={isSmallMobile ? "medium" : null}
        columns={
          isSmallMobile
            ? isSmallMobile
              ? ["auto"]
              : ["auto", "auto"]
            : ["75px", "75px", "flex", "75px", "75px", "flex", "75px", "75px"]
        }
        rows={
          isSmallMobile
            ? null
            : [
                "170px",
                "170px",
                "170px",
                "250px",
                "170px",
                "170px",
                "250px",
                "170px",
              ]
        }
        areas={
          isSmallMobile
            ? null
            : [
                {
                  name: "image1",
                  start: [size === "medium" || size === "small" ? 0 : 2, 0],
                  end: [2, 2],
                },
                { name: "text1", start: [4, 0], end: [6, 1] },
                { name: "text2", start: [1, 3], end: [2, 4] },
                {
                  name: "image2",
                  start: [5, 2],
                  end: [size === "medium" || size === "small" ? 7 : 6, 4],
                },
                { name: "image3", start: [0, 5], end: [3, 7] },
                { name: "text3", start: [5, 5], end: [5, 7] },
              ]
        }>
        <Box
          gridArea={isSmallMobile ? null : "image1"}
          fill={!isSmallMobile}
          flex={"grow"}>
          <StaticImage
            src={"../assets/images/we-build.jpg"}
            alt={"About us image 1"}
            className={"h-full w-full object-cover"}
          />
        </Box>
        <Box
          gridArea={isSmallMobile ? null : "text1"}
          fill={!isSmallMobile}
          flex={"grow"}
          pad={{
            horizontal: isSmallMobile ? "large" : null,
            vertical: isMobile ? "large" : null,
          }}>
          <Box flex="grow" alignSelf="start" justify="center">
            {!isSmallMobile && (
              <Heading level={1} color="red" size="large" margin="none">
                We
              </Heading>
            )}
            <Paragraph color="white" style={{ maxWidth: "400px" }}>
              A modern construction company with traditional values, our specialist divisions provide end-to-end construction excellence within the Healthcare, Commercial,  Education & Facilities sectors.
            </Paragraph>
          </Box>
        </Box>
        <Box
          gridArea={isSmallMobile ? null : "image2"}
          fill={!isSmallMobile}
          flex={"grow"}>
          <StaticImage
            src={"../assets/images/Bauvill_Ashford_231121-012.jpg"}
            alt={"About us image 2"}
            className={"h-full w-full object-cover"}
            />
        </Box>
        <Box
          gridArea={isSmallMobile ? null : "text2"}
          fill={!isSmallMobile}
          flex={"grow"}
          justify="center"
          pad={{
            horizontal: isSmallMobile ? "large" : null,
            vertical: isSmallMobile ? "large" : null,
          }}>
          {!isSmallMobile && (
            <Heading level={1} color="red" size="large" margin="none">
              Build
            </Heading>
          )}
          <Paragraph color="white" style={{ maxWidth: "400px" }}>
            Each dedicated division specialise in all forms of construction,
            refurbishment and fit-out within a range of operational
            environments.
          </Paragraph>
        </Box>
        <Box
          gridArea={isSmallMobile ? null : "image3"}
          fill={!isSmallMobile}
          flex={"grow"}>
          <StaticImage
            src={"../assets/images/placeholder3.png"}
            alt={"About us image 3"}
            className={"h-full w-full object-cover"}
          />
        </Box>

        <Box
          gridArea={isSmallMobile ? null : "text3"}
          fill={!isSmallMobile}
          flex={"grow"}
          justify="center"
          pad={{
            horizontal: isSmallMobile ? "large" : null,
            vertical: isSmallMobile ? "large" : null,
          }}>
          {!isSmallMobile && (
            <Heading level={1} color="red" size="large" margin="none">
              Ideas
            </Heading>
          )}
          <Paragraph color="white" style={{ maxWidth: "400px" }}>
            Our transparent process and customer comes first approach will
            always be at the heart of any project we deliver; irrespective of
            size, duration or complexity.
          </Paragraph>
        </Box>
      </Grid>
    </Box>
  );
};

const ContactForm = () => {
  const formRef = useRef();
  const messageRef = useRef();

  const { isMobile, isDesktop, themeSizes } = useResponsive();
  const [value, setValue] = useState({
    name: "",
    email: "",
    telephone: "",
    message: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const submitForm = () => {
    const formData = new FormData();
    formData.append("name", value.name);
    formData.append("email", value.email);
    formData.append("telephone", value.telephone);
    formData.append("message", value.message);

    fetch("https://getform.io/f/e6751948-7fdf-41a8-821a-6f10db95e03c", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setValue({
            name: "",
            email: "",
            telephone: "",
            message: "",
          });
        } else {
          setError(
            "There was a problem submitting the form. Please try again."
          );
          setTimeout(() => setError(false), 2000);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (success) {
      gsap.to(formRef.current, { opacity: 0, duration: 1 });
      gsap.to(messageRef.current, {
        opacity: 1,
        duration: 1,
        onComplete: () => {
          setTimeout(() => setSuccess(false), 2000);
        },
      });
    } else {
      gsap.to(formRef.current, { opacity: 1, duration: 1 });
      gsap.to(messageRef.current, { opacity: 0, duration: 1 });
    }
  }, [success]);

  return (
    <Box
      gap="large"
      pad={{ vertical: "xlarge" }}
      width={{
        max: "maxWidth",
        width: `calc(100% - ${themeSizes.large})`,
      }}
      style={{ position: "relative" }}>
      <Heading level={3} color="white" size="large" margin="none">
        Contact Form
      </Heading>

      <Form
        value={value}
        onChange={(nextValue) => setValue(nextValue)}
        onReset={() => setValue({})}
        onSubmit={({ value }) => {}}>
        <Heading
          ref={messageRef}
          color="white"
          level={3}
          style={{
            position: "absolute",
            textAlign: "center",
            top: "50%",
            left: 0,
            opacity: 0,
            right: 0,
            margin: "auto auto",
          }}>
          Thank you. We'll be in touch.
        </Heading>

        <Box
          ref={formRef}
          direction={isMobile ? "column" : "row"}
          gap="large"
          border={{ side: "top", size: "small", color: "white" }}>
          <Box basis="1/4">
            <Box
              pad={{ vertical: "3px" }}
              border={{ side: "bottom", size: "small", color: "white" }}>
              <FormField name="name" htmlFor="name">
                <TextInput id="name" name="name" placeholder="Name" />
              </FormField>
            </Box>
            <Box
              pad={{ vertical: "3px" }}
              border={{ side: "bottom", size: "small", color: "white" }}>
              <FormField name="telephone" htmlFor="telephone">
                <TextInput
                  id="telephone"
                  name="telephone"
                  placeholder="Telephone"
                />
              </FormField>
            </Box>
            <Box
              pad={{ vertical: "3px" }}
              border={{ side: "bottom", size: "small", color: "white" }}>
              <FormField name="email" htmlFor="email">
                <TextInput id="email" name="email" placeholder="Email" />
              </FormField>
            </Box>
          </Box>
          <Box basis="1/4" flex="grow">
            <Box
              pad={{ vertical: "small" }}
              border={{ side: "bottom", size: "small", color: "white" }}>
              <FormField name="message" htmlFor="message">
                <TextArea
                  id="message"
                  name="message"
                  style={{ height: 300 }}
                  placeholder="Type message here"
                />
              </FormField>
            </Box>
            <Box direction={isMobile ? "column" : "row"}>
              <Box flex>
                <div className={"mt-4 mb-6 desktop:mb-0"}>
                  <Button text={"Send"} onClick={submitForm} color={"light"} />
                </div>
                {error && <Paragraph color="red">{error}</Paragraph>}
              </Box>
              <Box flex>
                <Paragraph color="white">
                  By contacting us via our Contact Form, you give us consent to
                  use your data to reply to your query. We will not contact you
                  for any other purpose nor share your personal data with any
                  third-party organisations.
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

const WorkWithUs = () => {
  const { isMobile, isDesktop, themeSizes } = useResponsive();

  return (
    <Box
      pad={{ vertical: "xlarge" }}
      width={{ max: "maxWidth", width: `calc(100% - ${themeSizes.large})` }}
      gap="small">
      <Heading level={3} color="white" size="large" margin="none">
        Work With Us
      </Heading>
      <Box direction={isMobile ? "column" : "row"} gap="xlarge">
        <Box basis="1/2" flex>
          <Box flex="grow">
            <Paragraph color="white">
              Here at Bauvill we offer our employees opportunity and experience.
              If you are looking to further your career, whether it be work
              experience, an apprenticeship or a permanent role please email
              your CV and covering letter to enquiries@bauvill.com
            </Paragraph>
          </Box>
          <Box flex="shrink" className={"mt-4"}>
            <Category title="enquiries@bauvill.co.uk" />
          </Box>
        </Box>
        <Box basis="1/2" flex>
          <Box flex="grow">
            <Paragraph color="white">
              Bauvill welcomes applications from reputable suppliers,
              sub-contractors and manufacturers to join our supply chain, if you
              would like to be considered please email
            </Paragraph>
          </Box>
          <Box flex="shrink" className={"mt-4"}>
            <Category title="enquiries@bauvill.co.uk" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const Homepage = (props) => {
  const [index, setIndex] = useState(-1);
  const { isMobile, isDesktop, themeSizes, size } = useResponsive();

  return (
    <>
      <Box
        background="brand"
        flex="grow"
        pad={{ top: "xlarge" }}
        align="center">
        <IntroSection />
        <Box>
          <StaticImage
            src={"../assets/images/about/team.jpg"}
            alt={"About us image 1"}
            className={"h-auto w-screen object-cover"}
          />
        </Box>
        <Box
          fill="horizontal"
          width={{ max: "maxWidth" }}
          pad={{ top: "medium", horizontal: "large" }}
          direction="row">
          {isDesktop && <Box flex="grow" basis="1/3"></Box>}
          <Box basis={isDesktop ? "2/3" : null}>
            <Paragraph color="white">
              At Bauvill our directly employed specialist team speak through
              action, focusing on ensuring we are at the cutting edge of our
              field. We speak through action and our skills as engineers and
              project managers mean we deliver on time, to quality and in cost.
              We work with our customers and key stakeholders to build
              long-term, mutually beneficial relationships.
            </Paragraph>
          </Box>
        </Box>

        <DivisionShowcase showcaseType={"all"} style={"dark"} />

        <ContactForm />
        <Box>
          <StaticImage
            src={"../assets/images/metal_frames.jpg"}
            alt={"About us image 1"}
            className={"h-auto w-screen object-cover"}
          />
        </Box>
        <WorkWithUs />
        <Box
          flex={false}
          margin={{ vertical: "xlarge" }}
          alignSelf="center"
          height="60vh"
          width={{
            max: "maxWidth",
            width: `calc(100% - ${themeSizes.large})`,
          }}>
          <MapContainer />
        </Box>
      </Box>
    </>
  );
};

const HompageFrameWork = () => {
  return (
    <Layout isLandingPage white>
      <Seo title="About Us" />
      <Homepage />
    </Layout>
  );
};

export default HompageFrameWork;
